<template>
  <div>
    <data-header/>
    <div class="content">
      <div class="top-title flex align-center text-16 color-65">
        <div>周报时间</div>
        <el-date-picker @change="changeWeek" class="margin-left-20"
                        v-model="week"
                        type="week"
                        :clearable="false"
                        format="yyyy第WW周"
                        value-format="yyyy-MM-dd"
                        placeholder="选择周">
        </el-date-picker>
      </div>
      <div class="flex align-center justify-center margin-top-10 text-18 color-65">
        <div class="title-desc">{{ start }}至{{ end }} {{ data.oldPersonName }}（{{ data.oldPersonIdNum }}）睡眠检测周报信息</div>
      </div>

      <div class="flex align-center flex-wrap">
        <div class="content-content" :class="{'content-content-Collapse':!isCollapse}">
          <div class="content-content-title">睡眠状态</div>
          <div class="content-content-content" :class="{'content-content-content-Collapse':!isCollapse}"
               id="sleepState"></div>
        </div>
        <div class="content-content" :class="{'content-content-Collapse':!isCollapse}">
          <div class="content-content-title">睡眠质量数据</div>
          <div class="content-content-content" :class="{'content-content-content-Collapse':!isCollapse}">
            <div class="text-14 color-14CA65" style="margin-top: 16px;margin-left: 48px;">正常范围：6.0-9.0小时</div>
            <div class="sleep-quality-data " :class="{'sleep-quality-data-Collapse':!isCollapse}">
              <div class="sleep-quality-data-header color-59B8D9 text-16">
                <div>日期</div>
                <div>觉醒时长</div>
                <div>睡眠时长</div>
                <div>深睡时长</div>
                <div>浅睡时长</div>
                <div>打鼾时长</div>
              </div>
              <div class="sleep-quality-data-content text-14 color-3B95B4" v-for="(item,index) in data.sleepQuality"
                   :key="index">
                <div>{{ item.date }}</div>
                <div>{{ item.awakening }}分钟</div>
                <div>{{ item.allTotalSleep }}分钟</div>
                <div>{{ item.deepSleep }}分钟</div>
                <div>{{ item.lightSleep }}分钟</div>
                <div>{{ item.sdb }}分钟</div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-content" :class="{'content-content-Collapse':!isCollapse}">
          <div class="content-content-title">睡眠质量周报</div>
          <div class="content-content-content" :class="{'content-content-content-Collapse':!isCollapse}"
               id="weeklySleepQualityReport"></div>
        </div>
        <div class="content-content" :class="{'content-content-Collapse':!isCollapse}">
          <div class="content-content-title">平均心率</div>
          <div class="content-content-content" :class="{'content-content-content-Collapse':!isCollapse}"
               id="averageHeartRate"></div>
        </div>
        <div class="content-content" :class="{'content-content-Collapse':!isCollapse}">
          <div class="content-content-title">平均呼吸率</div>
          <div class="content-content-content" :class="{'content-content-content-Collapse':!isCollapse}"
               id="averageRespiratoryRate"></div>
        </div>
        <div class="content-content" :class="{'content-content-Collapse':!isCollapse}">
          <div class="content-content-title">体动</div>
          <div class="content-content-content" :class="{'content-content-content-Collapse':!isCollapse}"
               id="kinesis"></div>
        </div>
        <div class="content-content" :class="{'content-content-Collapse':!isCollapse}">
          <div class="content-content-title">呼吸事件</div>
          <div class="content-content-content" :class="{'content-content-content-Collapse':!isCollapse}"
               id="respiratoryEvents"></div>
        </div>
        <div class="content-content" :class="{'content-content-Collapse':!isCollapse}">
          <div class="content-content-title">打鼾</div>
          <div class="content-content-content" :class="{'content-content-content-Collapse':!isCollapse}"
               id="snore"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echats from "echarts";
import moment from 'moment'
import {mapActions} from "vuex";
import dataHeader from "./header";

export default {
  components: {dataHeader},
  data() {
    return {
      weekNum: "",
      id: "",
      start: "",
      end: "",
      week: "",
      data: {
        sleepState: [],
        sleepQuality: [],
        sleepQualityWeekly: []
      },
      myChartSleep: '',
      myChartWeekly: '',
      averageHeartRate: '',
      averageRespiratoryRate: '',
      kinesis: '',
      respiratoryEvents: '',
      snore: '',
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getTime(new Date())

  },
  computed: {
    "isCollapse": {
      get() {
        this.changeResize();
        return this.$store.state.isCollapse;
      },
      set(newValue) {
        this.$store.state.isCollapse = newValue;
      }
    },
  },
  mounted() {

  },
  methods: {
    ...mapActions(['getWeekReport']),
    changeResize() {
      if (this.myChartSleep != '') {
        this.myChartSleep.resize();
        this.myChartWeekly.resize();
        this.averageHeartRate.resize();
        this.averageRespiratoryRate.resize();
        this.kinesis.resize();
        this.respiratoryEvents.resize();
        this.snore.resize();
      }
    },
    changeWeek() {
      console.log(new Date(this.week))
      this.getTime(new Date(this.week));
    },
    getTime(date) {
      const weekOfDay = moment(date, "YYYY-MM-DD").format("E"); //计算今天是这周第几天
      this.start = moment(date).subtract(weekOfDay - 1, "days").format("YYYY-MM-DD"); //周一日期
      this.week = this.start
      //周日日期
      this.end = moment(new Date(this.start)).weekday(7).format("YYYY-MM-DD");
      this.getData();
    },

    getData() {
      this.getWeekReport({
        dateFrom: this.start,
        dateTo: this.end,
        oldPersonId: this.id,
      }).then(res => {
        if (res.code === 200) {
          this.data = res.data
          this.getSleepStatus();
          this.getWeeklySleepQualityReport();
          this.getEcharts("averageHeartRate", "（次）", 'rgb(227, 93, 127)', this.data.beatRate);
          this.getEcharts("averageRespiratoryRate", "（次）", 'rgb(98, 210, 177)', this.data.breathRate);
          this.getEcharts("kinesis", "（分）", 'rgb(219, 194, 109)', this.data.bodyMove);
          this.getEcharts("respiratoryEvents", "（次）", 'rgb(198, 98, 210)', this.data.breathEvent);
          this.getEcharts("snore", "（分）", 'rgb(109, 213, 219)', this.data.snoring);
        }
      })
    },

    /**
     * 睡眠状态
     */
    getSleepStatus() {
      const yData = this.data.sleepState.map((item) => item.date)
      const deepSleepRate = this.data.sleepState.map((item) => item.deepSleepRate)
      const lightSleepRate = this.data.sleepState.map((item) => item.lightSleepRate)
      const remRate = this.data.sleepState.map((item) => item.remRate)
      const awakeningRate = this.data.sleepState.map((item) => item.awakeningRate)
      this.myChartSleep = this.$echarts.init(document.getElementById('sleepState'))
      this.myChartSleep.setOption({
        tooltip: {
          show: false
        },
        legend: {
          top: 46,
          itemGap: 28,
          itemWidth: 12,
          itemHeight: 6,
          textStyle: {
            color: '#65C6E7',
            fontSize: 14
          }
        },
        grid: {
          left: '100',
          right: '40',
          bottom: '30',
          top: '86',
          containLabel: false
        },
        xAxis: {
          show: false,
        },
        yAxis: {
          type: 'category',
          data: yData,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#2574BF"
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#65C6E7',
              fontSize: 14
            }
          },
        },
        color: ['#2235C5', '#307BF5', '#52D0D5', '#BE554D', '#04234F'],
        series: [
          {
            name: '深睡',
            type: 'bar',
            stack: 'total',
            label: {
              show: false
            },
            barWidth: 20,
            showBackground: true,
            backgroundStyle: {
              color: '#04234F'
            },
            data: deepSleepRate
          },
          {
            name: '浅睡',
            type: 'bar',
            stack: 'total',
            label: {
              show: false
            },
            barWidth: 20,
            data: lightSleepRate
          },
          {
            name: 'REM',
            type: 'bar',
            stack: 'total',
            label: {
              show: false
            },
            barWidth: 20,
            data: remRate
          },
          {
            name: '觉醒',
            type: 'bar',
            stack: 'total',
            label: {
              show: false
            },
            barWidth: 20,
            data: awakeningRate
          },
          // {
          //   name: '离枕',
          //   type: 'bar',
          //   stack: 'total',
          //   label: {
          //     show: false
          //   },
          //   barWidth: 20,
          //   data: [820, 832, 901, 934, 1290, 1330, 1320]
          // }
        ]
      })
    },

    /**
     * 睡眠质量周报
     */
    getWeeklySleepQualityReport() {
      const date = this.data.sleepQualityWeekly.map((item) => item.date);
      const awakening = this.data.sleepQualityWeekly.map((item) => item.awakeningRate);
      const deepSleep = this.data.sleepQualityWeekly.map((item) => item.deepSleepRate);
      const lightSleep = this.data.sleepQualityWeekly.map((item) => item.lightSleepRate);
      const sdb = this.data.sleepQualityWeekly.map((item) => item.sdbRate);
      this.myChartWeekly = this.$echarts.init(document.getElementById('weeklySleepQualityReport'))
      this.myChartWeekly.setOption({
        tooltip: {
          show: false
        },
        legend: {
          top: 46,
          itemGap: 28,
          itemWidth: 12,
          itemHeight: 6,
          textStyle: {
            color: '#65C6E7',
            fontSize: 14
          }
        },
        grid: {
          left: '100',
          right: '40',
          bottom: '30',
          top: '86',
          containLabel: false
        },
        xAxis: {
          type: 'category',
          data: date,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#0E55B8"
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#65C6E7',
              fontSize: 12
            }
          },
        },
        yAxis: {
          name: "（%）",
          nameLocation: "end",
          nameTextStyle: {
            color: '#65C6E7',
            fontSize: 13,
            width: 12,
            align: 'left',
            padding: [0, 0, 0, -35]
          },
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: "#0E55B8"
            }
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#65C6E7',
              fontSize: 14
            }
          },
        },
        color: ['#2235C5', '#307BF5', '#52D0D5', '#BE554D', '#04234F'],
        series: [
          {
            name: '深睡',
            type: 'bar',
            label: {
              show: false
            },
            barWidth: 8,
            data: deepSleep
          },
          {
            name: '浅睡',
            type: 'bar',
            label: {
              show: false
            },
            barWidth: 8,
            data: lightSleep
          },
          {
            name: '打鼾',
            type: 'bar',
            label: {
              show: false
            },
            barWidth: 8,
            data: sdb
          },
          {
            name: '觉醒',
            type: 'bar',
            label: {
              show: false
            },
            barWidth: 8,
            data: awakening
          },
        ]
      })
    },

    /**
     * 折线图
     * @param idName
     */
    getEcharts(idName, yName, color, data) {
      const date = data.map((item) => item.date);
      const count = data.map((item) => item.count);
      this[idName] = this.$echarts.init(document.getElementById(idName))
      this[idName].setOption({
        tooltip: {
          show: false
        },
        legend: {
          show: false
        },
        grid: {
          left: '100',
          right: '40',
          bottom: '40',
          top: '74',
          containLabel: false
        },
        xAxis: {
          type: 'category',
          data: date,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#0E55B8"
            }
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#65C6E7',
              fontSize: 12
            }
          },
        },
        yAxis: {
          name: yName,
          nameLocation: "end",
          nameTextStyle: {
            color: '#65C6E7',
            fontSize: 13,
            width: 12,
            align: 'left',
            padding: [0, 0, 0, -35]
          },
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: "#0E55B8"
            }
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#65C6E7',
              fontSize: 14
            }
          },
        },
        series: [
          {
            type: 'line',
            label: {
              show: false
            },
            data: count,
            smooth: 'true',
            symbol: "none",
            lineStyle: {
              color: color,
              width: 4,
            },
            areaStyle: {
              normal: {
                color: new echats.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 0, color: color},
                      {offset: 0.7, color: 'rgb(255,255,255,0)'}
                    ]
                )
              }
            }
          },
        ]
      })
    }
  }
}
</script>

<style scoped>

/deep/ .el-date-editor.el-input, /deep/ .el-date-editor.el-input__inner {
  width: 30.91rem;
  height: 5.5rem;
  background: #000E18;
  border-radius: 4px;
}

/deep/ .el-input--prefix .el-input__inner {
  background: #000E18;
  border: 1px solid #3C62C0;
  color: #65C6E7;
}

/deep/ .el-input__inner:focus, /deep/ .el-input__inner /deep/ .el-input__inner:hover {
  background: #000E18;
  border: 1px solid #3C62C0;
}

/deep/ i {
  color: #3C62C0;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: calc(100%);
  background-color: #191a23;
  padding-bottom: 3.08rem;
}

.top-title {
  margin-left: 2.2rem;
  margin-top: 3.08rem;
}

.title {
  width: 30.91rem;
  height: 5.5rem;
  background: #000E18;
  border: 1px solid #3C62C0;
  border-radius: 4px;
  padding-left: 1.98rem;
}

.title-desc {
  width: 128.92rem;
  height: 7.7rem;
  background-image: url("../../../assets/images/smartElderly/title-框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  line-height: 7.7rem;
  text-align: center;
}

.content-content {
  display: flex;
  flex-direction: column;
  margin-top: 0.55rem;
  /*width: 88rem;*/
  width: 49.5%;
  height: 47.85rem;
  background-image: url("../../../assets/images/smartElderly/框.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.content-content-Collapse {
  /*width: calc(88rem + 128px);*/
}

.content-content-title {
  margin-top: 1.76rem;
  margin-left: 7.48rem;
  font-size: 1.76rem;
  color: #65C6E7;
}

.content-content-content {
  width: 84.15rem;
  height: 37.73rem;
  margin-top: 2.75rem;
  margin-left: 3.3rem;
}

.content-content-content-Collapse {
  width: calc(84.15rem + 128px);
}

.sleep-quality-data {
  width: 77rem;
  height: 29.15rem;
  background: rgba(0, 27, 54, 0.5);
  border: 1px solid #3C62C0;
  border-radius: 2px;
  padding: 0 1.1rem;
  margin-left: 2.64rem;
  margin-top: 1.32rem;
}

.sleep-quality-data-Collapse {
  width: calc(77rem + 128px);
}

.sleep-quality-data-header {
  display: flex;
  border-bottom: 1px solid #3C62C0;
}

.sleep-quality-data-header > div {
  width: calc(100% / 6);
  height: 5.28rem;
  line-height: 5.28rem;
  text-align: center;
}

.sleep-quality-data-content {
  display: flex;
  border-bottom: 1px solid #294384;
}


.sleep-quality-data-content > div {
  width: calc(100% / 6);
  height: 3.19rem;
  line-height: 3.19rem;
  text-align: center;
}
</style>
<style>
.el-main {
  border: none !important;
}
</style>
